




















































































































































// --- Vue & Template imports ---
import { Component, Prop, Vue } from 'vue-property-decorator';

// --- Models ---

// --- Services ---
import AppService from '@/services/app';
import AuthService from '@/services/auth';

// --- Third Party imports ---
import crypto from 'crypto-js';

@Component
export default class LoginPage extends Vue {
  user = {
    username: '',
    password: '',
  };

  valid: boolean = false;
  clicked: boolean = false;
  showPassword: boolean = false;
  
  rules = {
      required: (value: string) => !!value || '',
  };

  getCopyRight() {
    return process.env.VUE_APP_COPYRIGHT;
  }

  async login() {
    try {
      this.clicked = true;

      const response = await AuthService.login({ 
        username: this.user.username, 
        password: crypto.SHA256(this.user.password).toString().toUpperCase(),
      });

      if (response && response.data) {
        if (response.data.result && response.data.result === 'false') {
            AppService.errorHandler(response.data.message);
        } else {
            window.location.href = response.data.domain;
        }
      } else {
        // response is undefined or has no data field - SHOULD NEVER HAPPEN!
        throw new Error('response: ' + JSON.stringify(response));
      }

    } catch (error) {
      
      if (error.response) {
        AppService.errorHandler(error.response.statusText);
      } else {
        AppService.logSupportDebug('Login.vue - login - 206 - ' + error);
        AppService.errorHandler(this.$store.getters['app/messages'].couldNotConnect);
      }

    } finally {
      this.clicked = false;
    }
  }

  goToRegister() {
    this.$router.push({ name: 'Register'});
  }

  goToForgotPassword() {
    this.$router.push({ name: 'Forgotten Password'});
  }
}
